import { commonService } from './common';

const getDialog = (data) => commonService.request('POST', '/dialogs/help', { data }, commonService.HEADER_TYPES.AUTHORIZED);

const sendForm = (data) => commonService.request('POST', '/dialogs/help/form', { data }, commonService.HEADER_TYPES.AUTHORIZED);
const getDialogs = () => commonService.request('GET', '/dialogs', {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const getDialogById = (dialogId) => commonService.request('GET', `/dialogs/${dialogId}`, {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const getDialogItems = () => commonService.request('GET', '/dialogs/dialogitems', {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const createDialog = (data) => commonService.request('POST', '/dialogs', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const updateDialogById = (dialogId, data) => commonService.request('PUT', `/dialogs/${dialogId}`, { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const deleteDialog = (dialogId) => commonService.request('DELETE', `/dialogs/${dialogId}`, {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

export const dialogService = {
    updateDialogById,
    createDialog,
    deleteDialog,
    getDialogs,
    getDialogById,
    getDialogItems,
    getDialog,
    sendForm,
};
