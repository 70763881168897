import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    headerFabs: {
        position: 'fixed',
        zIndex: 1299,
        left: '50%',
        top: '0%',
        display: 'flex',
        transform: 'translateX(-50%)',
        width: '100%',
        ['@media (min-width:600px)']: { // eslint-disable-line no-useless-computed-key
            width: '640px',
        },
    },
});
export const HeaderIconGroup = withStyles(styles)((props) => {
    const { position, children, classes, ...other } = props;
    return (
        <div className={classes.headerFabs} style={{ justifyContent: position ? position : "flex-start" }}>
            {children}
        </div>
    )

});
