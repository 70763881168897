import { Grid, TextField, Box } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button } from '/components/common';
import { useEffect, useState } from 'preact/hooks';
import { useTranslation, Trans } from 'react-i18next';
import { useUserState } from '/lib/states';

import { useLocation } from 'wouter-preact';
import { dialogService } from '/services';
import Rating from '@material-ui/lab/Rating';
const styles = () => ({
    headerFabs: {
        position: 'fixed',
        zIndex: 1299,
        left: '50%',
        top: '0%',
        display: 'flex',
        transform: 'translateX(-50%)',
        width: '100%',
        ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
            width: '760px',
        },
    },
});
const FeedbackForm = withStyles(styles)((props) => {
    const { children, classes, ...other } = props;

    const { user } = useUserState();

    const labels = {
        0.5: 'Useless',
        1: 'Useless+',
        1.5: 'Poor',
        2: 'Poor+',
        2.5: 'Ok',
        3: 'Ok+',
        3.5: 'Good',
        4: 'Good+',
        4.5: 'Excellent',
        5: 'Excellent+',
    };

    const [location, setLocation] = useLocation();

    const [hover, setHover] = useState(-1);
    const [submitted, setSubmitted] = useState(false);
    const theme = useTheme();
    const [values, setValues] = useState({
        dialogId: 'contact-form',
        path: location,
        rating: 5,
        message: '',
    });
    const [errors, setErrors] = useState({

    });

    const handleClearError = (event) => {
        setErrors({
            ...errors,
            [event.target.name]: null,
        });
    };

    const handleChange = (event, newValue) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        setValues({
            ...values,
            [event.target.name]: value,
        });
    };

    useEffect(() => {
        setValues({
            ...values,
            dialogId: props.dialogId,
        });
        if (props.isSubmitted) {
            setSubmitted(true);
        }
    }, []);


    const submitForm = () => {
        setSubmitted(true);
        dialogService.sendForm(values);
        props.onFinish();
    }
    const { t } = useTranslation();

    return (
        <>
            {!submitted ? (<Grid container spacing={2}>
                <Grid item xs={12}>
                    <div>
                        <Rating
                            name="hover-feedback"
                            value={values.rating}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                setValues({ ...values, rating: newValue });
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                        />
                        {values.rating !== null && <Box ml={2}>{labels[hover !== -1 ? hover : values.rating]}</Box>}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="message"
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        onFocus={handleClearError}
                        id="message"
                        multiline
                        freeSolo
                        rows={6}
                        rowsMax={10}
                        label={t('common.message')}
                        value={values.message}
                        error={!!errors.message}
                        helperText={errors.message && errors.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="next"
                        color="primary"
                        onClick={() => submitForm()}
                    >
                        Abschicken
                </Button>
                </Grid>
            </Grid >) : (
                    <div> Vielen Dank für dein Feedback</div>
                )}</>
    )

});
export default FeedbackForm;