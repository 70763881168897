import { createState, useState } from '@hookstate/core';
import { ALERT } from '/lib/constants';
const alertState = createState({alerts: []})

export function useAlertState() {
    const state = useState(alertState)
        
    return ({
        add(alert, component) {
            state.alerts.set(alerts => 
                ([...alerts.filter(alert => alert.component !== component), {...alert, component}])
            )
        },
        addSuccess(alert, component) {
            state.alerts.set(alerts =>
                ([...alerts.filter(alert => alert.component !== component), {...alert, component,severity: ALERT.SUCCESS}])
            )
        },
        addError(alert, component) {
            state.alerts.set(alerts =>
                ([...alerts.filter(alert => alert.component !== component), {...alert, component, severity: ALERT.ERROR}])
            )
        },
        removeAlert(component) {
            state.alerts.set(alerts=> alerts.filter(alert => alert.component !== component))
        },
        getAlert(component) {
            return state.alerts.get(alerts=> alerts).find(alert => alert.component === component);
        }
    })   
}