import { Grid, TextField } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button } from '/components/common';
import { useEffect, useState } from 'preact/hooks';
import { useTranslation, Trans } from 'react-i18next';
import { useUserState } from '/lib/states';

import { useLocation } from 'wouter-preact';
import { dialogService } from '/services';
import { Autocomplete } from '@material-ui/lab';
const styles = () => ({
    headerFabs: {
        position: 'fixed',
        zIndex: 1299,
        left: '50%',
        top: '0%',
        display: 'flex',
        transform: 'translateX(-50%)',
        width: '100%',
        ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
            width: '760px',
        },
    },
});
const ContactForm = withStyles(styles)((props) => {
    const { children, classes, ...other } = props;

    const { user } = useUserState();
    const [subjectOptions, setSubjectOptions] = useState([
        "Anregungen",
        "Kursinhalt",
        "Kontakt",
        "Frage"
    ]);

    const [location, setLocation] = useLocation();

    const theme = useTheme();
    const [values, setValues] = useState({
        dialogId: 'contact-form',
        path: location,
        firstName: '',
        lastName: '',
        emailAddress: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({

    });

    const [submitted, setSubmitted] = useState(false);

    const handleClearError = (event) => {
        setErrors({
            ...errors,
            [event.target.name]: null,
        });
    };

    const handleChange = (event, newValue) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        setValues({
            ...values,
            [event.target.name]: value,
        });
    };
    useEffect(() => {

        setValues({
            ...values,
            dialogId: props.dialogId,
            firstName: user.first_name,
            lastName: user.last_name,
            emailAddress: user.username,
        });
        if (props.isSubmitted) {
            setSubmitted(true);
        }
    }, []);


    const submitForm = () => {
        setSubmitted(true);
        dialogService.sendForm(values);
        props.onFinish();
    }
    const { t } = useTranslation();

    return (
        <>
            {!submitted ? (
                < Grid container spacing={2} >
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="given-name"
                            name="firstName"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            id="firstName"
                            label={t('signup.addressDetails.firstName')}
                            autoFocus
                            value={values.firstName}
                            error={!!errors.firstName}
                            helperText={errors.firstName && errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="family-name"
                            name="lastName"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            onFocus={handleClearError}
                            id="lastName"
                            label={t('signup.addressDetails.lastName')}
                            value={values.lastName}
                            error={!!errors.lastName}
                            helperText={errors.lastName && errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="email"
                            name="emailAddress"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            onFocus={handleClearError}
                            id="emailAddress"
                            label={t('common.emailAddress')}
                            value={values.emailAddress}
                            error={!!errors.emailAddress}
                            helperText={errors.emailAddress && errors.emailAddress}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="subject"
                            fullWidth
                            options={subjectOptions}
                            onChange={handleChange}
                            onChange={(event, newValue) => {
                                //setValues({ ...values, subject: newValue });
                            }}
                            onInputChange={(event, newInputValue) => {
                                setValues({ ...values, subject: newInputValue });
                            }}

                            name="subject"
                            value={values.subject}
                            renderInput={(params) => <TextField {...params} label={t('common.subject')} variant="outlined" />}
                        />
                        {' '}

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="message"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            onFocus={handleClearError}
                            id="message"
                            multiline
                            freeSolo
                            rows={6}
                            rowsMax={10}
                            label={t('common.message')}
                            value={values.message}
                            error={!!errors.message}
                            helperText={errors.message && errors.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="next"
                            color="primary"
                            onClick={() => submitForm()}
                        >
                            Abschicken
                </Button>
                    </Grid>
                </Grid >) : (
                <div>Kontaktformular versandt</div>
            )}</>
    )

});
export default ContactForm;