import { commonService } from './common';

const getTreeChildren = (tree, parentId) => {
    const newTreeData = [];
    tree.map((node) => {
        if (node.parent_id === parentId) {
            newTreeData.push({
                id: node.id,
                title: node.name,
                subtitle: node.slug,
                expanded: true,
                isActive: node.isActive,
                data: node.data,
                children: getTreeChildren(tree, node.id),
            });
        }
        return node;
    });
    return newTreeData;
};

// TODO: check cache first
const getContent = (contentId, isInitial = false) => {
    return commonService.request('GET', `contents/${contentId}`, {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
};

const getPublicContents = (isInitial = false) => {
    return commonService.request('GET', 'public/contents', {}, commonService.HEADER_TYPES.PUBLIC).then((response) => response);
};

function getMenu(menuId = 'main_menu') {
    let menu = JSON.parse(sessionStorage.getItem(menuId));

    if (menu) {
        return Promise.resolve(menu);
    }
    return commonService.request('GET', `/menus/${menuId}`, {}, commonService.HEADER_TYPES.PUBLIC).then((response) => {
        const rawTree = [];
        response.MenuItems.map((item) => {
            rawTree.push({
                id: item.id,
                name: item.caption,
                title: item.caption,
                parent_id: item.parent_id,
                data: {
                    label: item.caption,
                    linkTo: item.object_details.linkTo,
                    itemType: item.object,
                    target: item.target,
                    accessRules: item.access_rules,
                },
                isActive: false,
            });
            return item;
        });
        menu = { menuRaw: rawTree, menuTree: getTreeChildren(rawTree, null) };
        sessionStorage.setItem(menuId, JSON.stringify(menu));
        return menu;
    });
}

export const contentService = {
    getPublicContents,
    getContent,
    getMenu,
};
