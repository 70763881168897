import { useTheme, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { HeaderIcon } from '/components/common/headerIcon';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: '40px',
        background: theme.palette.headerGradient.primary,

        position: 'fixed',
        left: '50%',
        top: '0%',
        transform: 'translateX(-50%)',
        maxWidth: '760px',
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '760px',
        },
    },

    appBarSecondary: {
        zIndex: theme.zIndex.drawer + 1,
        height: '40px',
        background: theme.palette.headerGradient.secondary,
        position: 'fixed',
        left: '50%',
        top: '0%',
        transform: 'translateX(-50%)',
        maxWidth: '760px',
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '760px',
        },
    },
    sectionDesktop: {
        position: 'absolute',
        right: '0',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    svg: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        marginBottom: -1,
        ['@media (min-width:760px)']: { // eslint-disable-line no-useless-computed-key
            height: 20,
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            height: '5vw',
        },
    },
});


export const DialogTitle = withStyles(styles)((props) => {
    const { titlePosition, direction, title, variant, children, classes, onClose, ...other } = props;
    const theme = useTheme();
    switch (variant) {
        case "primary":
        case "secondary":
            return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <AppBar id="trainerio-header" position="fixed" className={variant === "primary" ? classes.appBar : classes.appBarSecondary} elevation={0} align="center">
                        {direction !== 'flat' && <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <polygon fill={theme.palette.background.paper} points={(direction && direction === 'left' ? '0' : '100') + ",0 0,100 100,100"} />
                        </svg>}
                        <Toolbar
                        >
                            {(titlePosition && titlePosition === 'top') && < Typography variant="h6" style={{
                                color: "white",
                                transform: 'rotate(-3deg)',
                                marginTop: -14,
                                marginLeft: 30

                            }}>{title}</Typography>}
                        </Toolbar>
                        <Toolbar disableGutters className={classes.sectionDesktop}>
                            {onClose ? (
                                <HeaderIcon variant="close" onClick={onClose} />
                            ) : null}
                        </Toolbar>
                        {children}
                    </AppBar>
                    { (!titlePosition || titlePosition !== 'top') && < Typography variant="h6">{title}</Typography>}

                </MuiDialogTitle >
            );
            break;
        case "default":
        default:
            return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
    }

});