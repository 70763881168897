import axios from 'axios';
import { commonService } from './common';

const parseMailAddress = (mailAddress) => commonService.request('GET', `/onboarding/parse-mail-address/${mailAddress}`);

const parseSubDomain = (subdomain) => commonService.request('GET', `/onboarding/parse-subdomain/${subdomain}`);

const getLocationSuggestion = async () => axios.get('https://ipapi.co/json').then((response) => response.data);

const verifyEmail = (data) => commonService.request('PUT', '/onboarding/confirm-mail-address', {
    data,
}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const register = (data) => commonService.request('POST', '/onboarding', {
    data,
}, commonService.HEADER_TYPES.UPLOAD);

const updateRegistration = (data) => commonService.request('PUT', '/onboarding', {
    data,
}, commonService.HEADER_TYPES.UPLOAD);

export const onboardingService = {
    parseMailAddress,
    parseSubDomain,
    getLocationSuggestion,
    register,
    verifyEmail,
    updateRegistration,
};
