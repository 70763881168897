import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 400,
        minWidth: 344,
    },
    typography: {
        fontWeight: 'bold',
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#fddc6c',
        color: '#FFFFFF',
    },
    icons: {
        marginLeft: 'auto',
    },
    expand: {
        color: '#FFFFFF',
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
    },
}));

const SnackMessage = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);
    const { notification } = props;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDismiss = () => {
        closeSnackbar(props.id);
    };

    let caption = notification.caption;
    if (typeof caption !== 'undefined') {
        if (caption.length > 40) {
            caption = `${caption.substr(0, 40)}...`;
        }
    }
    const description = notification.description;


    let icon = <FolderIcon />;
    switch (notification.type) {
        case 'info':
            icon = <InfoIcon />;
            break;
        case 'error':
            icon = <ErrorIcon />;
            break;
        case 'success':
            icon = <SuccessIcon />;
            break;
        case 'warning':
            icon = <WarningIcon />;
            break;
        default:
            break;
    }

    return (
        <Card className={classes.card} ref={ref}>
            <CardActions classes={{ root: classes.actionRoot }} className={`avatar-${notification.type}`}>
                {icon}
                <Typography variant="subtitle2" className={classes.typography}>{caption}</Typography>
                <div className={classes.icons}>
                    <IconButton
                        aria-label="Show more"
                        className={classes.expand}
                        onClick={handleExpandClick}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    <IconButton className={classes.expand} onClick={handleDismiss}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit className="snackMesssage">
                <Paper className={classes.collapse} dangerouslySetInnerHTML={{ __html: description }} />
            </Collapse>
        </Card>
    );
});

SnackMessage.propTypes = {
    id: PropTypes.number.isRequired,
};

export default SnackMessage;
