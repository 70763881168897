import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { Switch as MuiSwitch } from '@material-ui/core';


export const Switch = withStyles((theme) => ({
    root: {
        width: 48,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(22px)',
            color: theme.palette.primary.main,
            '& + $track': {
                backgroundColor: theme.palette.primary.light,
                opacity: 1,
                border: `1px solid ${theme.palette.grey[300]}`,
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.primary.veryLight,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <MuiSwitch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});