import { commonService } from './common';

function loadNotifications(userId) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
        headers: commonService.getHeaders(commonService.HEADER_TYPES.AUTHORIZED),
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/${userId}/notification`, requestOptions).then(commonService.handleResponse).then((response) => response);
}

function setNotificationAsRead(userId, notificationId) {
    const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: commonService.getHeaders(commonService.HEADER_TYPES.AUTHORIZED),
        body: JSON.stringify({ shown_at: new Date() }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/${userId}/notification/${notificationId}`, requestOptions).then(commonService.handleResponse).then((response) => response);
}

function removeAllNotifications(userId) {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
        headers: commonService.getHeaders(commonService.HEADER_TYPES.AUTHORIZED),
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/${userId}/notification`, requestOptions).then(commonService.handleResponse).then((response) => response);
}

export const notificationService = {
    loadNotifications,
    setNotificationAsRead,
    removeAllNotifications,
};
