import SnackMessage from './snackMessage';
import { LOGIN_STATUS } from '/lib/constants';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
//import UIfx from 'uifx';
import { useEffect } from 'preact/hooks';
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
    transports: ['websocket'],
    autoConnect: false,
});
/*
const bell = new UIfx(
    "/assets/sounds/chat_notification.mp3",
    {
        volume: 0.8, // number between 0.0 ~ 1.0
        throttleMs: 100,
    },
);
*/


const SocketIo = (props) => {

    useEffect(() => {
        //const { loginStatus } = props;
        startSocketIo();
        /*
        if (loginStatus === LOGIN_STATUS.LOGGED_IN) {
            // this.startSocketIo();
        }
        */
       return stopSocketIo();
    }, []);


/*
    useSelector(prevProps) {
        const { loginStatus } = this.props;
        if (loginStatus === LOGIN_STATUS.LOGGED_IN && prevProps.loginStatus !== loginStatus) {
            this.stopSocketIo();
            this.startSocketIo();
        }
    }
    */

    const startSocketIo = () => {
        socket.disconnect();
        socket.connect();
        socket.on('connect', () => {
            //this.props.dispatch(userActions.setUserSocket(socket));
        });
        socket.on('error', (err) => {
        });
        socket.on('new_chat_message', handleChatMessage);
        socket.on('new_conversation_message', handleConversationMessage);
        socket.on('notify', handleNotify);
        socket.on('async_request_update', handleAsyncRequestUpdate);
        socket.on('updateTyping', handleUpdateTyping);
        socket.on('update-conversation-typing', handleUpdateConversationTyping);
    }

    const handleUpdateTyping = (data) => {
        //this.props.dispatch(chatActions.updateTyping(data));
    }

    const handleUpdateConversationTyping = (data) => {
        //this.props.dispatch(conversationActions.updateTyping(data));
    }

    const handleChatMessage = (data) => {
        //if (this.props.location.pathname.indexOf('/messenger') < 0) { bell.play(); }
        //this.props.dispatch(chatActions.updateChannels(data));
    }

    const handleConversationMessage = (data) => {
        //this.props.dispatch(conversationActions.updateChat(data));
    }

    const handleNotify = (msg) => {
        const { enqueueSnackbar } = useSnackbar();
        enqueueSnackbar(msg.caption, {
            variant: msg.type,
            content: (key) => (
                <SnackMessage id={key} notification={msg} />
            ),
        });
        //this.props.dispatch(notificationActions.addNotification(msg));
    }

    const handleAsyncRequestUpdate = (msg) => {

        // this.props.dispatch(asyncRequestActions.updateStatus(msg.clientRequestId, 'DONE', {
        //      type: msg.type, id: msg.mediaId, exerciseMediaId: msg.exerciseMediaId, workoutMediaId: msg.workoutMediaId, result: msg.result,
        //  }));
    }

    // eslint-disable-next-line class-methods-use-this
    const stopSocketIo = () => {
        socket.removeAllListeners('authenticated');
        socket.removeAllListeners('notify');
        socket.disconnect();
    }

    return (
        <div />
    );
}
export default SocketIo;
