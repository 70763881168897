import { useEffect, useState } from 'preact/hooks';
import {
    makeStyles, useTheme,
} from '@material-ui/core/styles';
import {
    Slide, Fade,
} from '@material-ui/core';

import { useLocation } from 'wouter-preact';
import { Button } from '/components/common';
import { dialogService } from '/services';
import parse from 'html-react-parser';
import ContactForm from './contactForm';
import FeedbackForm from './feedbackForm';

import ReactPlayer from 'react-player/file';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 350,
        zIndex: 2000,
        marginLeft: 5,
    },
    paper: {
        marginTop: 10,
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    conversationIcon: {
        color: 'white',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'left',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
}));

export default function Help(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [dialogs, setDialogs] = useState([]);
    const [submittedForms, setSubmittedForms] = useState([]);
    const [conversation, setConversation] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [location, setLocation] = useLocation();

    const feedbackTree = [
        {
            id: 'feedback-start',
            type: 'CHOICES',
            position: 'right',
            title: '',
            topic: 'FEEDBACK',
        },
        {
            parent_id: 'feedback-start',
            id: 'feedback-negative',
            type: 'PLAIN',
            position: 'left',
            title: 'Tut mir Leid, ich wäre gerne hilfreicher gewesen.',
            caption: '👎',
            topic: 'FEEDBACK',
        },
        {
            parent_id: 'feedback-negative',
            id: 'feedback-negative-contact',
            type: 'GENERAL_CONTACT',
            position: 'left',
            title: 'Bitte sende uns die folgenden Informationen, damit wir dir helfen können.',
            caption: '👎',
            topic: 'FEEDBACK',
        },
        {
            parent_id: 'feedback-start',
            id: 'feedback-positive',
            type: 'CHOICES',
            position: 'left',
            title: 'Prima! Ich freue mich, dass ich dir helfen konnte.<br />Damit ich meine Antworten weiter verbessern kann, würde ich mich über eine kurze Bewertung freuen.',
            caption: '👍',
            topic: 'FEEDBACK',
        },
        {
            parent_id: 'feedback-positive',
            id: 'feedback-yes',
            type: 'FEEDBACK_FORM',
            position: 'left',
            title: 'Bewerte die Konversation',
            caption: 'Bewerte die Konversation',
            topic: 'FEEDBACK',
        },

    ]

    const initComponent = async () => {
        setDialogs(await dialogService.getDialog({ path: location }));
    };

    useEffect(() => {
        initComponent();
    }, []);

    useEffect(() => {
        if (dialogs && dialogs.hasOwnProperty("items")) {
            startConversation();
        }
    }, [dialogs]);

    useEffect(() => {
        if (props.resetConversation === true) {
            resetConversation();
        }
    }, [props.resetConversation]);

    useEffect(() => {
        if (props.stepBack === true) {
            stepBack(answers[answers.length - 1]);
            props.stepBackDone();
        }
    }, [props.stepBack]);

    useEffect(() => {
        props.setAnswers(answers);
    }, [answers]);



    function wait(milisec) {
        return new Promise(resolve => {
            setTimeout(() => { resolve('') }, milisec);
        })
    }


    const startConversation = async () => {
        setAnswers([]);
        const dialogQuestion = dialogs.items.find((item) => item.parent_id === null);
        await wait(calcResponseDelay(250, 550));
        addToDialog(dialogQuestion, 'left');
        const dialogAnswers = dialogs.items.filter((item) => item.parent_id === dialogQuestion.id);
        await wait(calcResponseDelay(250, 550));
        addToDialog(dialogAnswers, 'right');
    }

    const addToDialog = (item, side, isSpecial = false) => {
        setConversation((prevState) => ([...prevState, { level: prevState.length, item, side, isSpecial }]));
    }

    const startFeedback = async (formId) => {

        setSubmittedForms((prevState) => ([...prevState, formId]));
        await wait(calcResponseDelay(250, 550));
        const dialogAnswers = feedbackTree.filter((item) => item.parent_id === 'feedback-start');
        addToDialog(dialogAnswers, 'right');
    }

    const finishDialog = async (formId) => {
        setSubmittedForms((prevState) => ([...prevState, formId]));
    }

    const renderItem = (level, item, side, selectedId = null, isSpecial = false) => {
        let itemContent = [parseContent(item.title)];
        let hasBubble = true;
        if (Array.isArray(item) && !selectedId) {
            {
                hasBubble = false;
                itemContent = item.map((answer) => (
                    <Button variant="outlined" color="primary" style={{ fontSize: '0.775rem', marginBottom: theme.spacing(1), textAlign: 'right', minWidth: '80px' }} padding={0} onClick={() => selectedId === null && updateDialogElement(level, answer.id)}>
                        {parseContent(answer.caption)}
                    </Button>
                ))
            }
        }
        if (selectedId) {
            itemContent = [<Fade in={true}><div style={{ minWidth: '70px', textAlign: 'center' }}>{parseContent(item.find(answer => answer.id === selectedId).caption)}</div></Fade>];
        }
        if (isSpecial) {
            switch (item.type) {
                case 'VIDEO':
                    itemContent = <ReactPlayer
                        className="react-player"
                        url={item.title}
                        width="100%"
                        height="100%"
                        controls
                    />;
                    break;
                case 'TRAINER_CONTACT':
                case 'GENERAL_CONTACT':
                    itemContent = <ContactForm dialogId={item.type} isSubmitted={submittedForms.includes(item.id)} onFinish={() => startFeedback(item.id)} />;
                    break;
                case 'FEEDBACK_FORM':
                    itemContent = <FeedbackForm dialogId={item.type} isSubmitted={submittedForms.includes(item.id)} onFinish={() => finishDialog(item.id)} />;
                    break;
                default:
                    itemContent = 'nothing special';
                    break;
            }

        }
        //TODO: use some makeStyles
        return (
            <div style={{ margin: '10px', textAlign: side, display: 'inline-flex', alignItems: side === 'left' ? 'flex-start' : 'flex-end', width: '100%', flexFlow: 'column' }}>
                <div className={"message"} style={{
                    borderRadius: '18px',
                    padding: '10px',
                    flexWrap: 'wrap',
                    width: (!hasBubble && !selectedId) ? '100%' : 'unset',
                    flexDirection: 'row',
                    borderBottomLeftRadius: side === 'left' ? 'unset' : '18px',
                    borderBottomRightRadius: side === 'right' ? 'unset' : '18px',
                    backgroundColor: isSpecial ? theme.palette.gray.light : (hasBubble ? side === 'left' ? theme.palette.gray.light : theme.palette.primary.main : 'unset'),
                    color: side === 'left' ? theme.palette.type === 'dark' ? 'white' : 'black' : 'white',
                    display: itemContent.length === 1 ? 'inline-block' : 'flex',
                    justifyContent: (!hasBubble && item.length === 2) ? 'space-evenly' : 'flex-end'
                }}>
                    {itemContent}
                </div>
                {
                    selectedId && <Fade in={true}><div style={{
                        borderBottomLeftRadius: '14px',
                        borderBottomRightRadius: '14px',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        fontSize: '0.75rem',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        padding: '0px 10px 5px 10px',
                        backgroundColor: hasBubble ? side === 'left' ? '#DEDFDE' : theme.palette.primary.light : 'unset',
                        color: side === 'left' ? 'black' : 'white',
                        display: itemContent.length === 1 ? 'inline-block' : 'flex',
                        justifyContent: (hasBubble && item.length) === 2 ? 'space-around' : 'flex-end'
                    }} onClick={() => stepBack(level)}>zurück</div></Fade>
                }
            </div >);
    }

    const updateDialogElement = async (level, selectedId) => {
        setAnswers((prevState) => ([...prevState, level]));
        const tempDialog = [...conversation];
        tempDialog[level] = { ...tempDialog[level], selectedId: selectedId }
        setConversation((prevState) => tempDialog);
        await wait(calcResponseDelay(250, 550));
        const selectedAnswer = { ...tempDialog[level].item.find(answer => answer.id === selectedId) };
        addToDialog(selectedAnswer, 'left');
        if (["TRAINER_CONTACT", "GENERAL_CONTACT", "FEEDBACK_FORM"].includes(selectedAnswer.type)) {
            await wait(calcResponseDelay(250, 550));
            addToDialog(selectedAnswer, 'left', true);
        }
        let dialogAnswers = dialogs.items.filter((item) => item.parent_id === selectedId);
        if (dialogAnswers.length === 0) {
            dialogAnswers = feedbackTree.filter((item) => item.parent_id === selectedId);

        }
        if (dialogAnswers.length > 0) {

            await wait(calcResponseDelay(250, 550));
            if (selectedAnswer.type === 'CHOICES') {

                addToDialog(dialogAnswers, 'right');
            } else {
                dialogAnswers = dialogAnswers[0];
                addToDialog(dialogAnswers, 'left');
                if (["TRAINER_CONTACT", "GENERAL_CONTACT", "FEEDBACK_FORM"].includes(dialogAnswers.type)) {
                    await wait(calcResponseDelay(250, 550));
                    addToDialog(dialogAnswers, 'left', true);
                }
            }
        } else {
            if (selectedAnswer.topic !== 'FEEDBACK') {
                startFeedback()
            }
        }

    }

    function calcResponseDelay(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }


    const resetConversation = () => {
        setConversation([]);
        setSubmittedForms([]);
        startConversation();
        props.resetDone();
    }
    const stepBack = (level) => {
        setAnswers((prevState) => ([...prevState].filter(answer => answer < level)));
        let tempDialog = [...conversation];
        tempDialog = tempDialog.filter(item => item.level <= level);
        tempDialog[level] = { ...tempDialog[level], selectedId: null }
        setConversation(tempDialog);
    }

    const parseContent = (content) => {
        return parse(content ? content : '');
    }

    return (
        <div>
            {
                dialogs.items && conversation.map((dialogItem, index) =>
                (<Slide direction="up" key={index} in={true} unmountOnExit>
                    {renderItem(dialogItem.level, dialogItem.item, dialogItem.side, dialogItem.selectedId, dialogItem.isSpecial)}
                </Slide>))
            }
        </div >
    );
}
